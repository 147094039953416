import React from "react";

export default function NewsWinners() {
  return (
    <section className="max-w-5xl px-6 mx-auto mt-8 mb-16 news-page">
      <h1 className="mx-auto text-2xl font-bold text-center text-white">
        WHOIS хакатон: 10 екипа, 10 функционалних апликација
      </h1>

      <p className="mt-8 mb-2 text-lg text-white md:mt-12">
        Жири онлајн WHOIS хакатона, који су током прошлог викенда (25. и 26.
        септембар) организовали Фондација Регистар националног интернет домена
        Србије (РНИДС) и компанија{" "}
        <a
          href="https://quantox.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Quantox Technology
        </a>
        , нашао се пред прилично тешким задатком – избором победника хакатона
        међу врло оштром конкуренцијом.
      </p>

      <p className="mt-6 mb-2 text-lg text-white">
        На првом месту нашла се екипа <i>Redtech Zemare</i> коју чине програмери
        Милан Цупаћ, Жељко Лучић, Игор Стевановић, Андрија Јеленковић и Дамјан
        Денић. На другом месту пратила их је екипа <i>Comfortaby Dumb</i> и
        чланови Давид Милинковић, Павле Пађин, Иван Јевтић, Михаило Трајковић,
        који су били готово раме уз раме са трећепласираном екипом{" "}
        <i>Trošica</i> која је окупила Јанка Ђурића, Наталију Остојић, Жељка
        Милојевића, Јанка Мирковића и Жељка Косовца.
      </p>

      <p className="mt-6 mb-2 text-lg text-white">
        Како је жири који су чинили Петар Словић, директор иновација,{" "}
        <i>Quantox Technology</i>, Дамјан Томић, технички директор{" "}
        <i>IT Biz Solutions</i>, и Душан Стојичевић, копредседавајући групе за
        Универзалну прихватљивост (
        <a href="https://uasg.tech/" target="_blank" rel="noopener noreferrer">
          UASG
        </a>
        ), више пута нагласио током представљања решења хакатона 27. септембра,
        о победницима су одлучиле нијансе. Свих десет екипа, одабраних за
        такмичење, испоручило је у потпуности функционалне апликације за мобилне
        телефоне које нуде WHOIS и DNS податке о слободним, односно
        регистрованим називима домена (ћириличким и латиничким) у задатим
        доменским просторима. Бројне екипе су отишле и корак даље укључивши више
        доменских простора и више података (нпр. поштански број регистрара).
        Занимљиво је да је свака екипа пројектном задатку приступила на
        оригиналан начин, користила сасвим различите технологије, а жири je имао
        прилике да види прилично иновативна решења, као што је употреба
        чет-бота.
      </p>

      <p className="mt-6 mb-2 text-lg text-white">
        Приликом оцењивања у обзир је узето више параметара – комплетност и
        релевантност решења, UI/UX дизајн, квалитет кода, скалабилност и
        презентација решења, а највише оцене наведених параметара одлучиле су о
        победницима.
      </p>

      <p className="mt-6 mb-2 text-lg text-white">
        Реализацију хакатона подржао је{" "}
        <a
          href="https://www.icann.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ICANN
        </a>
        , организација која управља глобалном адресном структуром интерната, и
        његова иницијатива <i>Universal Acceptance</i> основана са циљем да сви
        називи домена и имејл адресе на интернету буду подједнако третиране од
        стране софтверских апликација, без обзира на коришћено писмо. Учесници
        хакатона сагласили су се да рад са ћириличким доменима није представљао
        проблем, потврдивши још једном тезу да је за достизање Универзалне
        прихватљивости потребна само воља, јер технолошких препрека нема.
      </p>
    </section>
  );
}
