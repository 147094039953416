import React from "react";
import {
  ChevronDoubleRightIcon,
  ClockIcon,
  SwitchHorizontalIcon,
} from "@heroicons/react/outline";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";
import { useContext } from "react";

export default function HeaderSmall() {
  const { t } = useTranslation();
  const { language, languages, originalPath } = useContext(I18nextContext);
  const [srCyrl, srLatn] = languages;

  return (
    <header className="relative flex flex-col items-center justify-center h-48">
      <div className="absolute inset-0 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="/images/bg2.png"
          alt=""
        />
        <div
          className="absolute inset-0 bg-gray-800 mix-blend-color"
          aria-hidden="true"
        />
      </div>
      <Link to="/">
        <div className="relative items-center text-center md:flex">
          <div className="max-w-[100px] md:max-w-[100px] mb-6 mx-auto md:mr-6">
            <img
              src="/images/symbol.svg"
              alt=""
              className="w-full"
              width="563px"
              height="241px"
            />
          </div>

          <div className="max-w-[280px] md:max-w-[320px] mb-1 md:mb-2 overflow-hidden mx-auto">
            <img
              src="/images/whois.svg"
              alt=""
              className="w-full"
              width="563px"
              height="91px"
            />
          </div>
        </div>
      </Link>
    </header>
  );
}
